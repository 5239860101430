port module Main exposing (..)

import Api exposing (ApiData(..), ApiResult(..))
import Browser
import Css exposing (..)
import Date exposing (Date)
import Event exposing (Event)
import File exposing (File)
import Gallery
import Gallery.Image
import Html.Styled as Styled exposing (Html)
import Html.Styled.Attributes as Attributes exposing (css)
import Html.Styled.Events as Events
import Json.Decode as Decode exposing (Decoder)
import Json.Encode as Encode
import List.Extra as List
import Markdown
import News exposing (News)
import Process
import Task
import Time exposing (Month(..))
import User exposing (Fan, Role(..), User)


port saveToken : String -> Cmd msg


port receivedScrollToBottom : (Bool -> msg) -> Sub msg


port logout : () -> Cmd msg


type alias Flags =
    { maybeToken : Maybe String
    , width : Float
    , height : Float
    }


type alias Model =
    { dimensions : ( Float, Float )
    , state : State
    }


type State
    = InitialCheck
    | NotLoggedIn
        { email : String
        , password : String
        , mode : FormMode
        }
    | LoggedIn LoggedInState


type FormMode
    = Login (ApiData User)
    | Register (ApiData ())


type alias LoggedInState =
    { user : User
    , events : ApiData (List Event)
    , news : ApiData News
    , page : Page
    }


type Page
    = MainPage
    | SlideShowPage Gallery.State
    | EventEditPage EventEditForm
    | MoreStuff
    | AdminPage (ApiData (List Fan))


type alias EventEditForm =
    { action : EventFormAction
    , title : String
    , description : String
    , photos : List Event.Photo
    , photosToUpload : Maybe (List File)
    }


type EventFormAction
    = New
    | Update Int


type Msg
    = FormMsg FormMsg
    | ReceiveEvents (ApiResult (List Event))
    | ReceiveNews (ApiResult News)
    | ReceiveMe (ApiResult User)
    | ReceiveFans (ApiResult (List Fan))
    | LoadMoreEvents Int
    | GalleryMsg Gallery.Msg
    | EditEvent EventFormAction
    | EventFormMsg EventFormMsg
    | SelectPage Page
    | NoOp
    | Logout


type EventFormMsg
    = GotFiles (List File)
    | UploadFiles


type FormMsg
    = TypeEmail String
    | TypePassword String
    | OnLoginResult (ApiResult User)
    | OnRegisterResult (ApiResult User)
    | TryLogin
    | TryRegister
    | SetMode FormMode


init : Encode.Value -> ( Model, Cmd Msg )
init value =
    case Decode.decodeValue flagsDecoder value of
        Ok { maybeToken, width, height } ->
            case maybeToken of
                Just token ->
                    ( { dimensions = ( width, height ), state = InitialCheck }
                    , Api.me
                        { token = token
                        , onResult = ReceiveMe
                        }
                    )

                Nothing ->
                    ( { dimensions = ( width, height )
                      , state = initState
                      }
                    , Cmd.none
                    )

        Err a ->
            ( { dimensions = ( 0, 0 )
              , state = initState
              }
            , Cmd.none
            )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        NoOp ->
            ( model, Cmd.none )

        FormMsg formMsg ->
            updateForm formMsg model.state
                |> Tuple.mapFirst (\s -> { model | state = s })

        ReceiveEvents result ->
            ( { model | state = receiveEvents result model.state }
            , Task.perform (\_ -> LoadMoreEvents 2) (Process.sleep 1000)
            )

        LoadMoreEvents numToLoad ->
            loadMoreEvents numToLoad |> ifLoggedIn model

        ReceiveMe result ->
            receiveMe result model.state
                |> Tuple.mapFirst (\s -> { model | state = s })

        ReceiveFans result ->
            receiveFans result model.state
                |> Tuple.mapFirst (\s -> { model | state = s })

        ReceiveNews result ->
            receiveNews result model.state
                |> Tuple.mapFirst (\s -> { model | state = s })

        GalleryMsg galleryMsg ->
            ( { model | state = updateGallery galleryMsg model.state }
            , Cmd.none
            )

        EditEvent id ->
            openEventForm id |> ifLoggedIn model

        EventFormMsg eventFormMsg ->
            updateEventForm eventFormMsg |> ifLoggedIn model

        SelectPage page ->
            selectPage page
                |> ifLoggedIn model

        Logout ->
            ( { model | state = initState }, logout () )


selectPage : Page -> LoggedInState -> ( LoggedInState, Cmd Msg )
selectPage page loggedInState =
    let
        cmdForPage =
            case page of
                AdminPage NotFetched ->
                    Api.getFans
                        { token = loggedInState.user.token
                        , onResult = ReceiveFans
                        }

                _ ->
                    Cmd.none
    in
    ( { loggedInState | page = page }, cmdForPage )


updateEventForm : EventFormMsg -> LoggedInState -> ( LoggedInState, Cmd Msg )
updateEventForm eventFormMsg loggedInState =
    if User.isAdmin loggedInState.user then
        case loggedInState.page of
            EventEditPage form ->
                case eventFormMsg of
                    GotFiles files ->
                        let
                            newEventForm =
                                { form | photosToUpload = Just files }
                        in
                        ( { loggedInState | page = EventEditPage newEventForm }, Cmd.none )

                    UploadFiles ->
                        ( loggedInState
                        , Maybe.map2
                            (\photos eventId ->
                                Api.uploadImages
                                    { token = loggedInState.user.token
                                    , eventId = eventId
                                    , photos = photos
                                    , toMsg = NoOp
                                    }
                            )
                            form.photosToUpload
                            (case form.action of
                                Update eventId ->
                                    Just eventId

                                New ->
                                    Nothing
                            )
                            |> Maybe.withDefault Cmd.none
                        )

            _ ->
                ( loggedInState, Cmd.none )

    else
        ( loggedInState, Cmd.none )


loadMoreEvents : Int -> LoggedInState -> ( LoggedInState, Cmd Msg )
loadMoreEvents numToLoad loggedInState =
    let
        anyUnloaded =
            loggedInState.events
                |> Api.mapData (List.any (not << .loaded))
                |> Api.withDefault False
    in
    if anyUnloaded then
        let
            newEventData =
                loggedInState.events
                    |> Api.mapData (loadNextEvents numToLoad)
        in
        ( { loggedInState | events = newEventData }
        , Cmd.none
        )

    else
        ( loggedInState, Cmd.none )


openEventForm : EventFormAction -> LoggedInState -> ( LoggedInState, Cmd Msg )
openEventForm action loggedInState =
    case action of
        Update eventId ->
            let
                eventToEdit =
                    loggedInState
                        |> currentEvents
                        |> Maybe.map (List.filter (\e -> e.id == eventId))
                        |> Maybe.andThen List.head
                        |> Maybe.map fromEvent

                fromEvent e =
                    { title = e.title
                    , description = e.description
                    , action = Update e.id
                    , photos = e.photos
                    , photosToUpload = Nothing
                    }
            in
            case eventToEdit of
                Just e ->
                    ( { loggedInState | page = EventEditPage e }, Cmd.none )

                Nothing ->
                    ( loggedInState, Cmd.none )

        New ->
            -- TODO
            ( loggedInState, Cmd.none )


updateGallery : Gallery.Msg -> State -> State
updateGallery galleryMsg model =
    case model of
        LoggedIn loggedInState ->
            case loggedInState.page of
                SlideShowPage galleryState ->
                    LoggedIn
                        { loggedInState
                            | page =
                                SlideShowPage <| Gallery.update galleryMsg galleryState
                        }

                _ ->
                    model

        _ ->
            model


initState : State
initState =
    NotLoggedIn { email = "", password = "", mode = Login NotFetched }


ifLoggedIn : Model -> (LoggedInState -> ( LoggedInState, Cmd Msg )) -> ( Model, Cmd Msg )
ifLoggedIn model updater =
    case model.state of
        LoggedIn current ->
            current
                |> updater
                |> Tuple.mapFirst (\new -> { model | state = LoggedIn new })

        NotLoggedIn _ ->
            ( model, Cmd.none )

        InitialCheck ->
            ( model, Cmd.none )


receiveFans : ApiResult (List Fan) -> State -> ( State, Cmd Msg )
receiveFans fansResult state =
    case state of
        InitialCheck ->
            ( state, Cmd.none )

        NotLoggedIn _ ->
            ( state, Cmd.none )

        LoggedIn current ->
            case current.page of
                AdminPage _ ->
                    ( LoggedIn { current | page = AdminPage (Finished fansResult) }
                    , Cmd.none
                    )

                _ ->
                    ( state, Cmd.none )


receiveNews : ApiResult News -> State -> ( State, Cmd Msg )
receiveNews newsResult state =
    case state of
        InitialCheck ->
            ( state, Cmd.none )

        NotLoggedIn _ ->
            ( state, Cmd.none )

        LoggedIn current ->
            ( LoggedIn { current | news = Finished newsResult }, Cmd.none )


receiveMe : ApiResult User -> State -> ( State, Cmd Msg )
receiveMe meResult model =
    case model of
        InitialCheck ->
            case meResult of
                Success user ->
                    ( LoggedIn { user = user, events = Fetching, page = MainPage, news = Fetching }
                    , Cmd.batch
                        [ Api.getEvents { token = user.token, onResult = ReceiveEvents }
                        , Api.getNews { token = user.token, onResult = ReceiveNews }
                        ]
                    )

                Failed err ->
                    ( initState, Cmd.none )

        NotLoggedIn _ ->
            ( model, Cmd.none )

        LoggedIn _ ->
            ( model, Cmd.none )


receiveEvents : ApiResult (List Event) -> State -> State
receiveEvents eventsResult model =
    case model of
        LoggedIn current ->
            let
                sort evts =
                    evts
                        |> selectIf (not (User.isAdmin current.user)) .published
                        |> List.sortBy Event.toSortable

                events =
                    eventsResult
                        |> Api.mapResult sort
                        |> Api.mapResult (loadNextEvents 1)
            in
            LoggedIn { current | events = Finished events }

        NotLoggedIn _ ->
            model

        InitialCheck ->
            model


loadNextEvents : Int -> List Event -> List Event
loadNextEvents numToLoad events =
    List.foldl
        (\e acc ->
            if not e.loaded && acc.numUpdated < numToLoad then
                { events = { e | loaded = True } :: acc.events
                , numUpdated = acc.numUpdated + 1
                }

            else
                { acc | events = e :: acc.events }
        )
        { events = [], numUpdated = 0 }
        events
        |> .events
        |> List.reverse


updateForm : FormMsg -> State -> ( State, Cmd Msg )
updateForm formMsg model =
    case model of
        NotLoggedIn current ->
            case formMsg of
                TypeEmail str ->
                    ( NotLoggedIn { current | email = str }, Cmd.none )

                TypePassword str ->
                    ( NotLoggedIn { current | password = str }, Cmd.none )

                TryLogin ->
                    ( NotLoggedIn { current | mode = Login Fetching }
                    , Api.login
                        { email = current.email
                        , password = current.password
                        , onResult = FormMsg << OnLoginResult
                        }
                    )

                OnLoginResult loginResult ->
                    case loginResult of
                        Success user ->
                            ( LoggedIn { user = user, events = Fetching, page = MainPage, news = Fetching }
                            , Cmd.batch
                                [ saveToken user.token
                                , Api.getEvents { token = user.token, onResult = ReceiveEvents }
                                ]
                            )

                        Failed _ ->
                            ( NotLoggedIn { current | mode = Login (Finished loginResult) }
                            , Cmd.none
                            )

                TryRegister ->
                    ( NotLoggedIn { current | mode = Register Fetching }
                    , Api.register
                        { email = current.email
                        , password = current.password
                        , onResult = FormMsg << OnRegisterResult
                        }
                    )

                OnRegisterResult registerResult ->
                    case registerResult of
                        Success user ->
                            ( LoggedIn { user = user, events = Fetching, page = MainPage, news = Fetching }
                            , saveToken user.token
                            )

                        Failed e ->
                            ( NotLoggedIn { current | mode = Login (Finished registerResult) }
                            , Cmd.none
                            )

                SetMode mode ->
                    ( NotLoggedIn { current | mode = mode }, Cmd.none )

        _ ->
            ( model, Cmd.none )


view : Model -> Browser.Document Msg
view model =
    { title = "Sunny Strick"
    , body =
        [ Styled.toUnstyled <|
            Styled.div
                [ css
                    [ margin zero
                    , backgroundColor palette.black
                    , height (pct 100)
                    , color palette.white
                    , fontFamily monospace
                    , fontSize (px 16)
                    ]
                ]
                [ view_ model ]
        ]
    }


appWrapper : Style
appWrapper =
    batch
        [ maxWidth (px 600)
        , margin2 zero auto
        , padding (px 16)
        , height (pct 100)
        ]


loggedIn : State -> Maybe LoggedInState
loggedIn model =
    case model of
        LoggedIn loggedInState ->
            Just loggedInState

        NotLoggedIn _ ->
            Nothing

        InitialCheck ->
            Nothing


maybeImages : LoggedInState -> Maybe (List String)
maybeImages loggedInState =
    case loggedInState.events of
        Finished (Success events) ->
            events
                |> List.concatMap .photos
                |> List.map .large
                |> Just

        _ ->
            Nothing


showingSlideshow : LoggedInState -> Bool
showingSlideshow loggedInState =
    case ( maybeImages loggedInState, loggedInState.page ) of
        ( Just _, SlideShowPage _ ) ->
            True

        _ ->
            False


viewMenu : Bool -> LoggedInState -> Html Msg
viewMenu isSmallScreen loggedInState =
    let
        buttonLink { text, event } =
            Styled.a
                [ css [ textDecoration underline, cursor pointer ]
                , Events.onClick event
                ]
                [ Styled.text text ]
    in
    if showingSlideshow loggedInState then
        Styled.div
            [ css
                [ position fixed
                , top (px 32)
                , left (px 32)
                , border3 (px 2) solid palette.midPurp
                , padding (px 16)
                , displayFlex
                , cursor pointer
                , zIndex (int 10)
                ]
            , Events.onClick (SelectPage MainPage)
            ]
            [ Styled.text "x" ]

    else
        let
            slideshowButton =
                case maybeImages loggedInState of
                    Just slides ->
                        buttonLink
                            { text = "slideshow"
                            , event = SelectPage (SlideShowPage <| Gallery.init (List.length slides))
                            }

                    Nothing ->
                        Styled.text ""
        in
        Styled.div
            [ if not isSmallScreen then
                css
                    [ position fixed
                    , top (px 16)
                    , left (px 16)
                    , border3 (px 2) solid palette.midPurp
                    , padding (px 8)
                    , displayFlex
                    , flexDirection column
                    ]

              else
                css
                    [ position fixed
                    , zIndex (int 1)
                    , bottom (px 0)
                    , left (px 0)
                    , width (pct 100)
                    , padding (px 8)
                    , borderTop3 (px 2) solid palette.midPurp
                    , displayFlex
                    , flexDirection row
                    , justifyContent spaceAround
                    , backgroundColor palette.black
                    ]
            ]
            [ buttonLink
                { event = SelectPage MainPage
                , text = "home"
                }
            , slideshowButton
            , buttonLink
                { event = SelectPage MoreStuff
                , text = "extras"
                }
            , htmlIf (User.isAdmin loggedInState.user)
                (buttonLink
                    { event = SelectPage (AdminPage NotFetched)
                    , text = "users"
                    }
                )
            , Styled.a
                [ css [ textDecoration underline, cursor pointer ]
                , Events.onClick Logout
                ]
                [ Styled.text "bye" ]
            ]


paragraph : String -> Html Msg
paragraph str =
    Styled.p
        [ css [ paddingBottom (px 16) ] ]
        [ Styled.text str ]


view_ : Model -> Html Msg
view_ model =
    let
        isSmallScreen =
            if Tuple.first model.dimensions < 850 then
                True

            else
                False
    in
    case model.state of
        InitialCheck ->
            Styled.div [ css [ appWrapper ] ]
                [ Styled.h1 [ css [ color palette.mid, marginBottom (px 16) ] ]
                    [ Styled.text "Sunny's Photo Zone" ]
                , paragraph "..."
                ]

        NotLoggedIn form ->
            let
                { submitText, passPlaceholder, buttonAction, maybeProblem } =
                    case form.mode of
                        Login status ->
                            { submitText = "See Sunny!"
                            , passPlaceholder = ""
                            , buttonAction = FormMsg TryLogin
                            , maybeProblem = extractError status
                            }

                        Register status ->
                            { submitText = "Tell Lucy & Aaron"
                            , passPlaceholder = "make a password: "
                            , buttonAction = FormMsg TryRegister
                            , maybeProblem = extractError status
                            }
            in
            Styled.div [ css [ appWrapper ] ]
                [ Styled.h1 [ css [ color palette.mid, marginBottom (px 16) ] ]
                    [ Styled.text "Sunny's Photo Zone" ]
                , paragraph "Welcome to Sunny's lil corner of the internet."
                , paragraph "These photos are private. "
                , paragraph "If you want to see them, go ahead and make an account. Lucy & Aaron will be notified, and we'll approve you soon."
                , paragraph "Also, Aaron made this kinda quickly so there's probably a lot of jank. If you run into any issues- just text him!"
                , Styled.div
                    [ css
                        [ displayFlex
                        , flexDirection column
                        , width (px 300)
                        , border3 (px 1) solid palette.midPurp
                        ]
                    ]
                    [ let
                        makeButton text mode =
                            Styled.button
                                [ css
                                    [ buttonReset
                                    , displayFlex
                                    , justifyContent center
                                    , alignItems center
                                    , height (px 32)
                                    , lineHeight (px 32)
                                    , flexGrow (num 1)
                                    , cursor pointer
                                    , if formModeMatches form.mode mode then
                                        batch
                                            [ backgroundColor palette.midPurp
                                            , color palette.black
                                            ]

                                      else
                                        batch [ color palette.midPurp ]
                                    ]
                                , Events.onClick (FormMsg <| SetMode mode)
                                ]
                                [ Styled.text text ]
                      in
                      Styled.div
                        [ css
                            [ displayFlex
                            , justifyContent spaceBetween
                            , borderBottom3 (px 1) solid palette.midPurp
                            ]
                        ]
                        [ makeButton "login" (Login NotFetched)
                        , makeButton "register" (Register NotFetched)
                        ]
                    , Styled.form
                        [ css
                            [ displayFlex
                            , flexDirection column
                            , padding (px 16)
                            ]
                        , Events.onSubmit buttonAction
                        , Attributes.action "javascript:void(0);"
                        ]
                        [ Styled.label [ css [ displayFlex ] ]
                            [ Styled.span [ css [ width (pct 50), textAlign right, paddingRight (px 8) ] ] [ Styled.text "email:" ]
                            , Styled.input
                                [ Attributes.value form.email
                                , Events.onInput (FormMsg << TypeEmail)
                                , css [ marginBottom (px 16) ]
                                ]
                                []
                            ]
                        , Styled.label [ css [ displayFlex ] ]
                            [ Styled.span [ css [ width (pct 50), textAlign right, paddingRight (px 8) ] ] [ Styled.text "password:" ]
                            , Styled.input
                                [ Attributes.value form.password
                                , Attributes.type_ "password"
                                , Events.onInput (FormMsg << TypePassword)
                                , css [ marginBottom (px 16) ]
                                ]
                                []
                            ]
                        , Styled.input
                            [ Attributes.value submitText
                            , Attributes.type_ "submit"
                            ]
                            []
                        ]
                    , case maybeProblem of
                        Just errorMsg ->
                            Styled.div
                                [ css
                                    [ color palette.pop
                                    , padding (px 8)
                                    , borderTop3 (px 1) solid palette.midPurp
                                    ]
                                ]
                                [ viewErrors errorMsg ]

                        Nothing ->
                            Styled.text ""
                    ]
                ]

        LoggedIn loggedInState ->
            if loggedInState.user.role == Unconfirmed then
                Styled.div
                    [ css [ appWrapper ] ]
                    [ paragraph "If things are working properly, Aaron has been alerted that you wanna see the photos."
                    , paragraph "When he has a moment, he'll give you access and let you know!"
                    , paragraph "Feel free to text Lucy or Aaron directly to get things moving."
                    , Styled.a
                        [ css [ textDecoration underline, cursor pointer ]
                        , Events.onClick Logout
                        ]
                        [ Styled.text "bye" ]
                    ]

            else
                let
                    viewNews =
                        case loggedInState.news of
                            NotFetched ->
                                Styled.div [] [ Styled.text "" ]

                            Fetching ->
                                Styled.div [] [ Styled.text "" ]

                            Finished (Failed _) ->
                                Styled.div [] [ Styled.text "News Ticker Failed To Load" ]

                            Finished (Success data) ->
                                let
                                    viewItem item =
                                        Styled.div
                                            [ css
                                                [ display inlineBlock
                                                , borderRight3 (px 1) solid palette.mid
                                                , padding2 (px 8) (px 16)
                                                ]
                                            ]
                                            [ Styled.span [ css [ marginRight (px 8) ] ] [ Styled.text <| Date.format "MM-dd-YY" item.date ]
                                            , Styled.text item.text
                                            ]
                                in
                                Styled.node "marquee"
                                    [ css
                                        [ border3 (px 1) solid palette.mid
                                        , displayFlex
                                        , color palette.mid
                                        , marginBottom (px 16)
                                        ]
                                    ]
                                    (List.map viewItem data)
                in
                case loggedInState.page of
                    EventEditPage editing ->
                        viewEventForm loggedInState.user editing

                    MoreStuff ->
                        Styled.div
                            [ css [ appWrapper ] ]
                            [ Styled.section [ css [ paddingBottom (px 36) ] ]
                                [ header2 { extraCss = batch [], extraAttrs = [], text = "Media" }
                                , header3 { extraCss = batch [], extraAttrs = [], text = "Company Org Chart" }
                                , Styled.img
                                    [ Attributes.src "/org-chart.jpg"
                                    , Attributes.alt "An org chart showing Lucy as Queen, and Aaron as Chief Poop Officer"
                                    , css [ width (pct 100) ]
                                    ]
                                    []
                                , header3 { extraCss = batch [], extraAttrs = [], text = "The Due Date Song" }
                                , paragraph "In the final days of Lucy's pregnancy, when we were waiting for Sunny to come, stuck in a limbo time known as the zwischen, Aaron made this song."
                                , Styled.node "audio"
                                    [ Attributes.controls True, Attributes.src "/zwischen.mp3" ]
                                    [ Styled.text "Your browser does not support audio elements" ]
                                , header3 { extraCss = batch [], extraAttrs = [], text = "Rainbow Cover, from BB & HenHen" }
                                , paragraph "Sunny's talented fam sent over this cover of the Kasey Musgrave's smash they made for her."
                                , Styled.node "video"
                                    [ Attributes.controls True, Attributes.src "https://link.storjshare.io/s/jukxemuhtphez36lap2btqxwstbq/sunny-photo-images/sunny-rainbow.mp4?wrap=0" ]
                                    [ Styled.text "Your browser does not support video elements" ]
                                , header3 { extraCss = batch [], extraAttrs = [], text = "Sunny painting from Karen" }
                                , paragraph "My good friend mom Karen painted Sunny this lovely animal painting."
                                , Styled.img
                                    [ Attributes.src "/sunny-painting.jpg"
                                    , Attributes.alt "An elephant holds balloons that say Sunny while animals prance about"
                                    , css [ width (pct 100) ]
                                    ]
                                    []
                                , header3 { extraCss = batch [], extraAttrs = [], text = "Sunny in Darkness Tee" }
                                , paragraph "Want one of these super metal Sunny shirts? Lemme know"
                                , Styled.img
                                    [ Attributes.src "/sunny-in-darkness.jpg"
                                    , Attributes.alt "Aaron models a dope metal Sunny in Darkness ultrasound Tee"
                                    , css [ width (pct 100) ]
                                    ]
                                    []
                                ]
                            , Styled.section [ css [ paddingBottom (px 36) ] ]
                                [ header2 { extraCss = batch [], extraAttrs = [], text = "About Sunny" }
                                , paragraph "Sunny was born May 13th, 2022 at 11:57pm."
                                , paragraph "She weighed 7lbs 13oz. She was 20\" tall and head a 15\" head."
                                , paragraph "She was born at the San Francisco Birth Center."
                                , paragraph "Her dad is Aaron, her mom is Lucy, her brother is Pilot."
                                ]
                            , Styled.section [ css [ paddingBottom (px 36) ] ]
                                [ header2 { extraCss = batch [], extraAttrs = [], text = "About this site" }
                                , header3 { extraCss = batch [], extraAttrs = [], text = "Roadmap" }
                                , Styled.ul []
                                    [ Styled.li [] [ Styled.text "Email notifications/alerts" ]
                                    , Styled.li [] [ Styled.text "\"Pilot's corner\"" ]
                                    , Styled.li [] [ Styled.text "Being able to favorite images" ]
                                    ]
                                , header3 { extraCss = batch [], extraAttrs = [], text = "Update Log" }
                                , Styled.ul []
                                    [ Styled.li [] [ Styled.text "06-28-22: Add rainbow cover & metal tee" ]
                                    , Styled.li [] [ Styled.text "06-18-22: Improve image loading, higher quality slideshow." ]
                                    , Styled.li [] [ Styled.text "05-20-22: Click images for full size original" ]
                                    , Styled.li [] [ Styled.text "05-19-22: Add org chart and some site info" ]
                                    , Styled.li [] [ Styled.text "05-17-22: Add News Ticker" ]
                                    ]
                                , header3 { extraCss = batch [], extraAttrs = [], text = "Inspiration" }
                                , Styled.p [ css [ paddingBottom (px 16) ] ]
                                    [ Styled.text "I wanted a private way to share photos of Sunny. I thought "
                                    , Styled.a [ Attributes.href "https://www.notabli.com/" ] [ Styled.text "notabli" ]
                                    , Styled.text " seemed great, but it also seemed fun to build my own, so I could play with"
                                    , Styled.text " fun extra ideas I had."
                                    , Styled.a [ Attributes.href "https://www.andy.works/words/no-more-boring-apps" ] [ Styled.text "...and also sort of this." ]
                                    ]
                                , Styled.p [ css [ paddingBottom (px 16) ] ]
                                    [ Styled.text "I also read an "
                                    , Styled.a [ Attributes.href "https://dubroy.com/blog/getting-things-done-in-small-increments/" ] [ Styled.text "inspiring article " ]
                                    , Styled.text "about how programming, which often takes large chunks of time is difficult as a parent, "
                                    , Styled.text "so it's good to get good at working in small chunks. "
                                    , Styled.text "This site is a sort of way to practice that, finding 20-40 minutes a day to make progress."
                                    ]
                                , header3 { extraCss = batch [], extraAttrs = [], text = "How it's made" }
                                , Styled.p [ css [ paddingBottom (pc 16) ] ]
                                    [ Styled.text "This site was built with "
                                    , Styled.a [ Attributes.href "https://rubyonrails.org/" ] [ Styled.text "Ruby on Rails" ]
                                    , Styled.text "for the backend, and "
                                    , Styled.a [ Attributes.href "https://elm-lang.org" ] [ Styled.text "Elm " ]
                                    , Styled.text "for the frontend. It's hosted on Heroku."
                                    ]
                                ]
                            , viewMenu isSmallScreen loggedInState
                            ]

                    MainPage ->
                        Styled.div
                            [ css [ appWrapper ] ]
                            [ viewNews
                            , paragraph "Pardon the fact that updates have been slow! It's hard with a baby!"
                            , paragraph "If you see new content, I recommend scrolling down a bit. I'm updating in big batches, 1 new things probably means many new things."
                            , viewEvents loggedInState
                            , viewMenu isSmallScreen loggedInState
                            ]

                    SlideShowPage galleryState ->
                        case maybeImages loggedInState of
                            Just images ->
                                let
                                    config =
                                        Gallery.config
                                            { id = "gallery"
                                            , transition = 300
                                            , width = Gallery.vw 100
                                            , height = Gallery.vh 100
                                            }
                                in
                                Styled.div []
                                    [ viewMenu isSmallScreen loggedInState
                                    , images
                                        |> List.map
                                            (\url -> ( "hi", Gallery.Image.slide url Gallery.Image.Contain ))
                                        |> Gallery.view config galleryState [ Gallery.Arrows ]
                                        |> Styled.fromUnstyled
                                        |> Styled.map GalleryMsg
                                    ]

                            Nothing ->
                                Styled.text ""

                    AdminPage fanData ->
                        let
                            viewFan fan =
                                Styled.div [ css [ displayFlex, justifyContent spaceBetween ] ]
                                    [ Styled.span [] [ Styled.text fan.email ]
                                    , Styled.span [] [ Styled.text (User.roleToString fan.role) ]
                                    ]
                        in
                        Styled.div [ css [ appWrapper ] ]
                            [ Styled.p [ Events.onClick (SelectPage MainPage), css [ marginBottom (px 16) ] ]
                                [ Styled.text "BACK" ]
                            , case fanData of
                                Finished (Success fans) ->
                                    Styled.div []
                                        [ Styled.p [ css [ marginBottom (px 16) ] ] [ Styled.text <| "(" ++ String.fromInt (List.length fans) ++ ") users" ]
                                        , Styled.div [] (List.map viewFan fans)
                                        ]

                                Finished (Failed err) ->
                                    Styled.div []
                                        (List.map Styled.text (Api.failureToStrings err))

                                _ ->
                                    Styled.text "loading"
                            ]


viewEventForm : User -> EventEditForm -> Html Msg
viewEventForm user form =
    let
        anyPhotos =
            case form.photosToUpload of
                Just [] ->
                    False

                Nothing ->
                    False

                Just a ->
                    True

        newPhotos =
            Styled.form
                [ Attributes.form "photo-form"
                , Attributes.action "javascript:void(0);"
                , css [ displayFlex, flexDirection column ]
                , Events.onSubmit (EventFormMsg UploadFiles)
                ]
                [ Styled.input
                    [ Attributes.type_ "file"
                    , Attributes.multiple True
                    , Events.on "change" (Decode.map (EventFormMsg << GotFiles) filesDecoder)
                    ]
                    []
                , if anyPhotos then
                    Styled.input
                        [ Attributes.type_ "submit"
                        , Attributes.value "upload photos"
                        ]
                        []

                  else
                    Styled.text ""
                ]
    in
    Styled.div [ css [ width (px 600) ] ]
        [ Styled.form
            [ css
                [ displayFlex
                , flexDirection column
                , padding (px 16)
                ]
            , Attributes.action "javascript:void(0);"
            , Attributes.form "my-form"
            ]
            [ Styled.label [ css [ displayFlex ] ]
                [ Styled.span [ css [ width (pct 50), textAlign right, paddingRight (px 8) ] ] [ Styled.text "title:" ]
                , Styled.input
                    [ Attributes.value form.title

                    -- , Events.onInput (FormMsg << TypeEmail)
                    , css [ marginBottom (px 16) ]
                    ]
                    []
                ]
            , Styled.label [ css [ displayFlex ] ]
                [ Styled.span [ css [ width (pct 50), textAlign right, paddingRight (px 8) ] ] [ Styled.text "description:" ]
                , Styled.textarea
                    [ Attributes.value form.description
                    , Attributes.form "my-form"

                    --               , Events.onInput (FormMsg << TypePassword)
                    , css [ marginBottom (px 16) ]
                    ]
                    []
                ]
            , Styled.input
                [ Attributes.value "update"
                , Attributes.type_ "submit"
                ]
                []
            ]
        , newPhotos
        ]


viewEvents : LoggedInState -> Html Msg
viewEvents loggedInState =
    case loggedInState.events of
        NotFetched ->
            Styled.text "getting"

        Fetching ->
            Styled.text "loading"

        Finished (Failed failure) ->
            viewErrors (Api.failureToStrings failure)

        Finished (Success events) ->
            let
                loadedEvents =
                    List.takeWhile .loaded events
            in
            Styled.div [ css [ displayFlex, flexDirection column ] ]
                (List.map (displayEvent loggedInState.user) loadedEvents)


viewErrors : List String -> Html msg
viewErrors errors =
    Styled.ul []
        (List.map (\s -> Styled.li [] [ Styled.text s ]) errors)


currentEvents : LoggedInState -> Maybe (List Event)
currentEvents loggedInState =
    case loggedInState.events of
        NotFetched ->
            Nothing

        Fetching ->
            Nothing

        Finished (Failed failure) ->
            Nothing

        Finished (Success events) ->
            Just events


displayEvent : User -> Event -> Html Msg
displayEvent user event =
    let
        displayPhoto photo =
            if event.loaded then
                Styled.div
                    [ css
                        [ marginBottom (px 16)
                        , width (pct 100)
                        , displayFlex
                        , flexDirection column
                        ]
                    ]
                    [ Styled.a
                        [ css [ width (pct 100) ]
                        , Attributes.href photo.original
                        , Attributes.target "_blank"
                        ]
                        [ Styled.img
                            [ Attributes.src photo.medium
                            , css [ width (pct 100) ]
                            ]
                            []
                        ]
                    , case photo.caption of
                        Just c ->
                            Styled.div
                                [ css
                                    [ border3 (px 1) solid palette.midPurp
                                    , borderTop zero
                                    , padding (px 8)
                                    ]
                                ]
                                [ Styled.text c ]

                        Nothing ->
                            Styled.text ""
                    ]

            else
                Styled.text ""
    in
    Styled.div [ css [ displayFlex, flexDirection column, marginBottom (px 64) ] ]
        [ header2
            { extraCss = cssIf (User.isAdmin user) (cursor pointer)
            , extraAttrs =
                [ attributeIf (User.isAdmin user) (Events.onClick <| EditEvent (Update event.id))
                ]
            , text = event.title
            }
        , Styled.p [ css [ paddingBottom (px 8), color palette.midPurp ] ]
            [ Styled.text <| displayDate event.date ]
        , Styled.div [ css [ paddingBottom (px 24) ] ]
            [ Styled.fromUnstyled <| Markdown.toHtml [] event.description ]
        , Styled.div
            [ css
                [ displayFlex
                , flexDirection column
                , alignItems flexStart
                ]
            ]
            (List.map displayPhoto event.photos)
        ]


header2 : { extraAttrs : List (Styled.Attribute msg), extraCss : Style, text : String } -> Html msg
header2 { extraAttrs, extraCss, text } =
    Styled.h2
        (css
            [ color palette.mid
            , fontSize (px 48)
            , marginBottom (px 24)
            , extraCss
            ]
            :: extraAttrs
        )
        [ Styled.text text ]


header3 : { extraAttrs : List (Styled.Attribute msg), extraCss : Style, text : String } -> Html msg
header3 { extraAttrs, extraCss, text } =
    Styled.h2
        (css
            [ color palette.midPurp
            , fontSize (px 24)
            , marginBottom (px 16)
            , extraCss
            ]
            :: extraAttrs
        )
        [ Styled.text text ]


subscriptions : Model -> Sub Msg
subscriptions model =
    case model.state of
        LoggedIn m ->
            case currentEvents m of
                Just _ ->
                    receivedScrollToBottom
                        (\atBottom ->
                            if atBottom then
                                LoadMoreEvents 1

                            else
                                NoOp
                        )

                Nothing ->
                    Sub.none

        _ ->
            Sub.none


main : Program Encode.Value Model Msg
main =
    Browser.document
        { view = view
        , init = init
        , update = update
        , subscriptions = subscriptions
        }


flagsDecoder : Decoder Flags
flagsDecoder =
    Decode.map3 Flags
        (Decode.field "savedToken" (Decode.maybe Decode.string))
        (Decode.field "width" Decode.float)
        (Decode.field "height" Decode.float)


displayDate : Date -> String
displayDate date =
    let
        sunnyGuess =
            Date.fromCalendarDate 2022 May 13

        dayDiff =
            Date.diff Date.Days sunnyGuess date

        stringDate =
            Date.format "MMMM dd" date

        age =
            if dayDiff < 0 then
                String.concat
                    [ Date.diff Date.Weeks sunnyGuess date
                        |> abs
                        |> String.fromInt
                    , " weeks before"
                    ]

            else if dayDiff < 21 then
                String.concat
                    [ Date.diff Date.Days sunnyGuess date
                        |> abs
                        |> String.fromInt
                    , " days old"
                    ]

            else
                String.concat
                    [ Date.diff Date.Weeks sunnyGuess date
                        |> abs
                        |> String.fromInt
                    , " weeks old."
                    ]
    in
    stringDate ++ ", " ++ age


palette =
    { white = rgb 246 240 237
    , pop = rgb 254 151 66
    , darkPop = hsl 27 0.9 0.4
    , mid = rgb 141 135 15
    , dark = rgb 60 52 31
    , grey = rgb 78 70 65
    , black = rgb 15 15 15
    , midPurp = hsl 275 0.57 0.71
    }


buttonReset : Css.Style
buttonReset =
    batch
        [ border zero
        , width auto
        , backgroundColor transparent
        , color inherit
        , fontFamily inherit
        ]


extractError : ApiData a -> Maybe (List String)
extractError data =
    case data of
        Finished (Failed failure) ->
            Just (Api.failureToStrings failure)

        _ ->
            Nothing


formModeMatches : FormMode -> FormMode -> Bool
formModeMatches a b =
    case ( a, b ) of
        ( Login _, Login _ ) ->
            True

        ( Register _, Register _ ) ->
            True

        _ ->
            False


cssIf : Bool -> Style -> Style
cssIf pred style =
    if pred then
        style

    else
        batch []


attributeIf : Bool -> Styled.Attribute msg -> Styled.Attribute msg
attributeIf pred event =
    if pred then
        event

    else
        Attributes.class ""


htmlIf : Bool -> Html msg -> Html msg
htmlIf pred html =
    if pred then
        html

    else
        Styled.text ""


filesDecoder : Decoder (List File)
filesDecoder =
    Decode.at [ "target", "files" ] (Decode.list File.decoder)


selectIf : Bool -> (a -> Bool) -> List a -> List a
selectIf bool filter list =
    if bool then
        List.filter filter list

    else
        list
