import { Elm } from "./Main.elm";

const SAVED_TOKEN = "saved-token";

const mount = document.getElementById("mount");

const savedToken = localStorage.getItem(SAVED_TOKEN);

const width = window.innerWidth;
const height = window.innerHeight;

const flags = {
  savedToken,
  height,
  width,
};

const app = Elm.Main.init({
  node: mount,
  flags,
});

app.ports.saveToken.subscribe((token) => {
  localStorage.setItem(SAVED_TOKEN, token);
});

app.ports.logout.subscribe(() => {
  localStorage.removeItem(SAVED_TOKEN);
});


let lastKnownScrollPosition = 0;
let ticking = false;

const sendScroll = (scrollPos) => {
  const atBottom = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 200

  app.ports.receivedScrollToBottom.send(atBottom)
}

document.addEventListener('scroll', function(e) {
  lastKnownScrollPosition = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function() {
      sendScroll(lastKnownScrollPosition);
      ticking = false;
    });

    ticking = true;
  }
});
